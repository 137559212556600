
import { Vue, Component } from "vue-property-decorator"
import {
  AllJobTitlesQuery,
  ActivatePeraltaAccountMutation,
  ActivatePeraltaAccountMutationMutation,
  InviteConsultantMutation,
  InviteConsultantMutationMutation,
} from "@/gql"

import { FetchResult } from "apollo-link"

@Component
export default class InviteConsultant extends Vue {
  readonly AllJobTitlesQuery = AllJobTitlesQuery
  consultantName: string | null = null
  actionLoading = false

  defaultFormData = {
    firstName: "",
    lastName: "",
    email: "",
    jobTitleId: 0,
  }

  formData = { ...this.defaultFormData }

  async inviteConsultant() {
    const result = await this.mutate<InviteConsultantMutationMutation>({
      mutation: InviteConsultantMutation,
      variables: this.formData,
    })
    if (result && result.data && result.data.inviteConsultant.error) {
      if (
        result.data.inviteConsultant.error.code &&
        result.data.inviteConsultant.error.code == "101"
      ) {
        this.consultantName = `${this.formData.firstName} ${this.formData.lastName}`
      }
    } else {
      this.consultantName = null
      this.formData = { ...this.defaultFormData }
      this.$emit("close")
      this.addSuccess("Consultant invited")
    }
  }

  async activatePeraltaProfile() {
    this.actionLoading = true
    const result: FetchResult<ActivatePeraltaAccountMutationMutation> = await this.mutate({
      mutation: ActivatePeraltaAccountMutation,
      variables: {
        email: this.formData.email,
        jobTitleId: this.formData.jobTitleId,
      },
    })
    if (result?.data?.activatePeraltaAccount) {
      this.actionLoading = false
      this.$emit("close")
      this.addSuccess("Consultant invited to Peralta successfully")
    }
  }
}
